import {
    BirthCardContainer,
    CountryCodeBlock,
    ErrorMessage,
    SelectBeforContainer,
    Submit,
} from './styles'
import { EventTracking } from '@/helpers'
import { CountryListArray } from '@/helpers/getCountryListArray'
import { getOTPAPI } from '@/utils/api-ssr'
import { Input, Select, Space } from 'antd'
import countries from 'i18n-iso-countries'
import { useSession } from 'next-auth/react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { FlagIcon } from 'react-flag-kit'
import { Controller, useForm } from 'react-hook-form'

export const Otp = ({
    setStep,
    setMessage,
    setShowToast,
    setOtpId,
    setPhone,
    countryCode,
    setCountryCode,
    phone,
}) => {
    const { data: session } = useSession()

    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
    })

    const onSubmit = async () => {
        try {
            EventTracking('login', { loginType: 'phone' })

            let mobileNumber = phone?.startsWith('0')
                ? phone?.replace('0', '')
                : phone

            const request = {
                channels: ['sms'],
                countryCode,
                mobileNumber,
            }
            if (!phone) {
                setShowToast(true)
                setMessage('Nomor ponsel harus diberikan')
                return
            }
            const response = await getOTPAPI(request, session?.token)

            if (response && response?.error_service) {
                const errorData = JSON.parse(response?.error_service?.http_body)
                EventTracking(
                    'otp_request_sent',
                    {
                        screenName: 'Registration_PhoneNumber',
                        phoneNumber: phone,
                        status: 'Failed',
                        trackOnPostHog: true,
                    },
                    session
                )
                EventTracking(
                    'input_phone_number_page_opened',
                    {
                        screenName: 'Registration_PhoneNumber',
                        source: 'phone number',
                        status: 'Failed',
                        trackOnPostHog: true,
                    },
                    session
                )
                setShowToast(true)
                setMessage(errorData?.message)
                return
            }

            if (response?.message === 'success') {
                const otpId = response?.data?.id

                EventTracking(
                    'otp_request_sent',
                    {
                        screenName: 'Registration_PhoneNumber',
                        phoneNumber: phone,
                        status: 'Succeed',
                        trackOnPostHog: true,
                    },
                    session
                )
                EventTracking(
                    'input_phone_number_page_opened',
                    {
                        screenName: 'Registration_PhoneNumber',
                        source: 'phone number',
                        status: 'Succeed',
                        trackOnPostHog: true,
                    },
                    session
                )

                setOtpId(otpId)
                setPhone(phone)
                setCountryCode(countryCode)
                setStep(2)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const inputSuffix = () => {
        if (errors.phone)
            return (
                <img
                    src="/img/cross_rounded.svg"
                    alt="cross"
                    width="20px"
                    height="20px"
                />
            )
    }

    const [searchValue, setSearchValue] = useState('')
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
    const countryData = countries.getNames('en', { select: 'official' })

    const getDialCode = (countryCode) => {
        return CountryListArray.find((a) => a.code == countryCode)?.dial_code
    }
    const countryOptions = Object.entries(countryData).map(([code, name]) => ({
        value: code,
        label: name,
        flag: code.toUpperCase(),
        dialCode: getDialCode(code),
    }))

    const filteredOptions = countryOptions.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
    )

    const handleSearch = (value) => {
        setSearchValue(value)
    }

    const dropdownRender = (menu) => (
        <>
            <div style={{ padding: 4, borderBottom: '1px solid white' }}>
                <Input
                    className="custom-placeholder"
                    prefix={<img src={'/Navigation.svg'} alt="" />}
                    placeholder="Cari negara"
                    bordered={false}
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{
                        width: '100%',
                        marginBottom: 6,
                        background: '#333333',
                        color: '#949494',
                    }}
                />
            </div>
            {menu}
        </>
    )

    const selectBefore = (
        <SelectBeforContainer>
            <Select
                style={{ width: '65px' }}
                filterOption={false}
                onSearch={handleSearch}
                dropdownRender={dropdownRender}
                dropdownStyle={{ width: 325, background: '#333333' }}
                value={'+' + countryCode}
                onChange={(value) => {
                    if (!value) {
                        return
                    }
                    setCountryCode(value?.replace('+', ''))
                }}
            >
                {filteredOptions?.length <= 0 && (
                    <div
                        style={{
                            textAlign: 'center',
                            fontFamily: 'Readex Pro',
                            fontSize: '14px',
                            color: '#FFFFFF',
                            cursor: 'not-allowed',
                        }}
                    >
                        <img
                            src="/country-not-found.svg"
                            alt="country not found"
                        />
                        <div
                            style={{
                                fontWeight: '600',
                                letterSpacing: '0.1px',
                                lineHeight: '17.5px',
                                marginTop: '10px',
                            }}
                        >
                            Negara Gak Ketemu
                        </div>
                        <div
                            style={{
                                fontWeight: '400',
                                color: '#B9B9B9',
                                letterSpacing: '0.25px',
                                lineHeight: '22.4px',
                                padding: '20px',
                            }}
                        >
                            <span style={{ width: '10px' }}>
                                Coba cek ejaan kamu atau masuk dengan metode
                                lain
                            </span>
                        </div>
                    </div>
                )}
                {filteredOptions.map((option) => {
                    return (
                        <Select.Option
                            key={option.value}
                            value={option.dialCode}
                            className="custom-select-option"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                }}
                            >
                                <FlagIcon code={option.flag} size={24} />
                                <span
                                    style={{
                                        marginLeft: '20px',
                                        color: 'white',
                                        fontFamily: 'Readex Pro',
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    {option.label} ({option.dialCode})
                                </span>
                            </div>
                        </Select.Option>
                    )
                })}
            </Select>
            <CountryCodeBlock>+{countryCode}</CountryCodeBlock>
        </SelectBeforContainer>
    )

    return (
        <form id="otp-form" onSubmit={handleSubmit(onSubmit)}>
            <BirthCardContainer>
                <Space
                    style={{
                        width: '100%',
                        border: errors.phone && '1px solid #FFA9A9',
                        borderRadius: '8px',
                        background: '#1F1F1F',
                    }}
                    direction="vertical"
                    className="otp-select"
                >
                    <Controller
                        control={control}
                        name="phone"
                        // rules={{
                        //     pattern: {
                        //         value: indonesiaPhoneRegex,
                        //         message:
                        //             'Format nomer tidak sesuai. Coba lagi ya!',
                        //     },
                        //     required: 'Belum ada nomer yang dimasukkin',
                        // }}
                        render={() => (
                            <Input
                                id="phone"
                                addonBefore={selectBefore}
                                suffix={inputSuffix()}
                                placeholder="81234789894"
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        )}
                    />
                </Space>

                {errors.phone && (
                    <ErrorMessage>{errors.phone.message}</ErrorMessage>
                )}

                <Submit disabled={!phone} type="submit" mobileNumber={phone}>
                    {isSubmitting ? <Spinner size="sm" /> : 'Masuk'}
                </Submit>
            </BirthCardContainer>
        </form>
    )
}
