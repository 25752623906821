import { LoginButton } from '../LoginModal/styles'
import { EventTracking } from '@/helpers/eventTracking'
import AppleLogin from 'react-apple-login'

export const Apple = () => {
    const handleClick = () => {
        EventTracking('login', { loginType: 'apple' })
    }

    return (
        <AppleLogin
            responseType="code id_token"
            responseMode="fragment"
            autoLoad={false}
            clientId={process.env.NEXT_PUBLIC_APPLE_ID || ''}
            redirectURI={`${process.env.NEXT_PUBLIC_ROOT_URL}`}
            render={({ onClick }) => (
                <>
                    <LoginButton
                        onClick={() => {
                            handleClick()
                            onClick()
                        }}
                    >
                        <img src="/img/apple_login.svg" alt="" />
                    </LoginButton>
                </>
            )}
        />
    )
}
