import { OtpTimer } from './OtpTimer'
import {
    BirthCardContainer,
    MobileNumber,
    OtpErrorMessage,
    Submit,
    Timer,
} from './styles'
import { EventTracking } from '@/helpers'
import { isMalicious } from '@/helpers/isMalicious'
import { getOTPAPI } from '@/utils/api-ssr'
import { getSession, signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import OtpInput from 'react-otp-input'

export const OtpForm = ({
    otpId,
    phone,
    setMessage,
    setShowToast,
    setOtpId,
    setStep,
    countryCode,
}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [otp, setOtp] = useState('')
    const [sendingOtp, setSendingOtp] = useState(false)

    const { data: session } = useSession()

    const router = useRouter()
    const redirect_uri = isMalicious(router.query.redirect_uri)
        ? router.pathname
        : router.query.redirect_uri

    useEffect(() => {
        EventTracking(
            'input_otp_page_opened',
            {
                screenName: 'Registration_OTP',
                phoneNumber: phone,
                trackOnPostHog: true,
            },
            session
        )
    }, [])

    const sendOtp = async () => {
        setSendingOtp(true)

        EventTracking(
            'otp resend requested',
            {
                screenName: 'Registration_OTP',
                phoneNumber: phone,
            },
            session
        )

        try {
            let mobileNumber = phone?.startsWith('0')
                ? phone?.replace('0', '')
                : phone

            const request = {
                channels: ['sms'],
                countryCode: countryCode,
                mobileNumber,
            }

            const response = await getOTPAPI(request, session?.token)

            if (response && response?.error_service) {
                const errorData = JSON.parse(response?.error_service?.http_body)
                setMessage(errorData?.message)
                setShowToast(true)
                setSendingOtp(false)
                return
            }

            if (response?.message === 'success') {
                const otpId = response?.data?.id
                setOtpId(otpId)
            }
        } catch (err) {
            console.log(err)
        }

        setSendingOtp(false)
    }

    return (
        <BirthCardContainer>
            <MobileNumber style={{ marginTop: '-8px' }}>
                <span>
                    +{countryCode} {phone}
                </span>

                <button
                    onClick={() => {
                        EventTracking(
                            'change phone number requested',
                            {
                                screenName: 'Registration_OTP',
                                phoneNumber: phone,
                            },
                            session
                        )

                        setStep(1)
                    }}
                >
                    Ubah
                </button>
            </MobileNumber>

            <OtpInput
                isInputNum
                containerStyle="otp-box-container"
                inputStyle="otp-box"
                value={otp}
                onChange={(value) => {
                    setError(false)
                    setErrorMessage('')
                    setOtp(value)
                }}
                numInputs={4}
                className={error ? 'otp-error' : ''}
            />

            {error && (
                <OtpErrorMessage>
                    {errorMessage ||
                        'Isi OTP dengan 4 digit angka yang dikirim ke nomermu lewat SMS'}
                </OtpErrorMessage>
            )}

            <Timer>
                <span>Belum terima kode OTP?</span>

                {sendingOtp ? (
                    <Spinner style={{ color: '#fad810' }} size="sm" />
                ) : (
                    <span className="time-count">
                        <OtpTimer setStep={sendOtp} />
                    </span>
                )}
            </Timer>

            <Submit
                onClick={() => {
                    if (!otp?.length || otp.length < 4) {
                        EventTracking(
                            'otp_verification_requested',
                            {
                                screenName: 'Registration_OTP',
                                phoneNumber: phone,
                                status: 'Failed',
                                trackOnPostHog: true,
                            },
                            session
                        )
                        setError(true)
                    }

                    if (otp?.length === 4) {
                        EventTracking(
                            'otp_verification_requested',
                            {
                                screenName: 'Registration_OTP',
                                phoneNumber: phone,
                                status: 'Succeed',
                                trackOnPostHog: true,
                            },
                            session
                        )

                        setError(false)
                        setLoading(true)

                        const formattedPhone = phone?.startsWith('0')
                            ? phone?.replace('0', '')
                            : phone

                        signIn('otp', {
                            otp,
                            otpId,
                            token: session?.token,
                            phone: formattedPhone,
                            guestTokenResponse: JSON.stringify(session),
                            redirect: false,
                        })
                            .then(async () => {
                                setLoading(false)
                                const session = await getSession()

                                if (session?.errorMessage) {
                                    setError(true)
                                    setErrorMessage(session?.errorMessage)
                                    return
                                } else {
                                    router.replace(
                                        redirect_uri || router.asPath
                                    )
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                                setLoading(false)
                            })
                    }
                }}
            >
                {loading ? <Spinner size="sm" /> : 'Verifikasi'}
            </Submit>
        </BirthCardContainer>
    )
}
