import { Otp } from '@/components/Registration/Otp'
import { OtpForm } from '@/components/Registration/OtpForm'
import { ToastMessage } from '@/components/Registration/Toast'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useState } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export const OtpLoginOption = () => {
    const [step, setStep] = useState(1)
    const [showToast, setShowToast] = useState(false)
    const [message, setMessage] = useState('')
    const [otpId, setOtpId] = useState('')
    const [phone, setPhone] = useState('')
    const [countryCode, setCountryCode] = useState('62')

    return (
        <>
            <div style={{ width: '100%' }}>
                {step === 1 && (
                    <Otp
                        setStep={setStep}
                        setMessage={setMessage}
                        setShowToast={setShowToast}
                        setOtpId={setOtpId}
                        phone={phone}
                        setPhone={setPhone}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                    />
                )}

                {step === 2 && (
                    <OtpForm
                        setStep={setStep}
                        setMessage={setMessage}
                        setShowToast={setShowToast}
                        otpId={otpId}
                        phone={phone}
                        countryCode={countryCode}
                        setOtpId={setOtpId}
                    />
                )}
            </div>

            <ToastMessage
                message={message}
                showToast={showToast}
                setShowToast={setShowToast}
            />
        </>
    )
}
