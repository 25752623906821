import { useEffect, useState } from 'react'

export const useCheckIsInAppBrowser = () => {
    const [isInAppBrowser, setIsInAppBrowser] = useState(false)

    useEffect(() => {
        const userAgent = navigator.userAgent

        const isInAppBrowser =
            userAgent.includes('Instagram') ||
            userAgent.includes('FBAN') ||
            userAgent.includes('FBAV') ||
            userAgent.includes('Twitter') ||
            userAgent.includes('LinkedIn') ||
            userAgent.includes('Vine') ||
            userAgent.includes('VK') ||
            userAgent.includes('Messenger') ||
            userAgent.match(/YԹ\/\d+\.\d+/) ||
            userAgent.includes('TikTok') ||
            userAgent.includes('BytedanceWebview')

        setIsInAppBrowser(isInAppBrowser)
    }, [])

    return isInAppBrowser
}
