import { ResendOtp } from './styles'
import { useEffect, useState } from 'react'

export const OtpTimer = ({ setStep }) => {
    const [seconds, setSeconds] = useState(60)

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds - 1)
        }, 1000)

        if (seconds === 0) clearInterval(interval)

        return () => clearInterval(interval)
    }, [seconds])

    const formatSeconds = seconds.toLocaleString(undefined, {
        minimumIntegerDigits: 2,
    })

    if (seconds > 0) return <> 00:{formatSeconds}</>

    return <ResendOtp onClick={() => setStep(1)}>Kirim Ulang OTP</ResendOtp>
}
