import { LoginButton } from '../LoginModal/styles'
import { EventTracking } from '@/helpers/eventTracking'
import { signIn } from 'next-auth/react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

export const Facebook = ({ token }) => {
    const responseFacebook = (response) => {
        if (response?.accessToken) {
            signIn('facebook', {
                accessToken: response?.accessToken,
                redirect: false,
                token,
            })
            return
        }
    }

    const handleClick = () => {
        EventTracking('login', { loginType: 'facebook' })
    }

    return (
        <FacebookLogin
            appId={process.env.NEXT_PUBLIC_FACEBOOK_ID}
            autoLoad={false}
            fields="name,email,picture"
            onClick={handleClick}
            callback={responseFacebook}
            disableMobileRedirect={true}
            render={(renderProps) => (
                <LoginButton onClick={renderProps.onClick}>
                    <img src="/img/facebook_login.svg" alt="" />
                </LoginButton>
            )}
        />
    )
}
