import { ColumnFlex, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
`

export const InnerLoginContainer = styled.div`
     {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }
`
export const ModalContent = styled.div`
     {
        min-width: 400px;
        padding: 10px;
        background: #141414 !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
    }
`

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
`

export const CloseContainer = styled.div`
    ${RowFlex}
    justify-content: flex-end;
    margin-right: 10px;
    width: 100%;
    margin-top: 2px;
    cursor: pointer;
`

export const CloseIcon = styled.img`
    width: 16px;
    height: 16px;
`
export const NoiceLogoContainer = styled.div`
     {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`

export const NoiceLogo = styled.img`
    width: 100px;
    height: 28.15px;
`
export const LoginContentContainer = styled.div`
     {
        ${ColumnFlex}
        justify-content: center;
        align-items: center;
        gap: 24px;
        position: relative;
        padding-top: ${(props) => (props.isTransactionPage ? '27px' : '40px')};
        padding-bottom: ${(props) =>
            props.isTransactionPage ? '20px' : '36px'};
    }
`

export const LoginImage = styled.img`
    width: 180px;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
`
export const ModalPrimaryText = styled.div`
     {
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        font-weight: 600;
        line-height: 27.5px;
        text-align: center;
    }
`

export const ModalSecondaryText = styled.div`
     {
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0.1px;

        margin-top: -8px;
        margin-bottom: 8px;
    }
`

export const LoginOptionsContainer = styled.div`
     {
        display: flex;
        gap: 10px;
        width: 100%;
    }
`
export const LoginIcon = styled.img`
     {
        width: 48px;
        height: 48px;
        cursor: pointer;
    }
`
export const ModalTnCText = styled.div`
     {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        text-align: center;

        color: rgba(255, 255, 255, 0.8);
    }
`

export const LoginButton = styled.button`
    border: none;
    backgroun: none;

    height: 52px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 14px;
    background: #fff;

    color: #000;
    text-align: center;

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;

    padding: 9px 24px;
    flex: 1 0 0;
`

export const Divider = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;

    width: 100%;

    color: #949494;
    text-align: center;

    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.5px;

    .divide {
        height: 2px;
        border-radius: 100px;
        background: #949494;
    }
`
